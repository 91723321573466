import React, { useEffect, useContext, useState } from "react";
import MissionSVG from "../../../svg/MissionStatementSection/tccmission.svg";
import WindowSizeContext from "../../contexts/windowSizeContext";

/**
 * This component renders the mission statement block used in the Landing Page.
 *
 * @returns {element} A section element containing the layout, text and images for the mission statement.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const [isLargeScreenView, setIsLargeScreenView] = useState(windowSize.isLargeScreenView);

  useEffect(() => {
    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [windowSize.isLargeScreenView]);

  return (
    <section>
      <div className="container mx-auto">
        <div className="flex flex-wrap pt-1 overflow-hidden lg:pt-10">
          <div className="w-full overflow-hidden xl:w-2/12"></div>

          <div className="flex flex-wrap w-full overflow-hidden text-center lg:text-left xl:w-8/12">
            <div className="flex flex-col pt-10 pb-10 pr-4 lg:w-7/12">
              <h1 className="text-2xl">La misión de Comparación</h1>
              <p className="pt-8">
                El mundo es ya bastante estresante y complicado en estos momentos para tener que andar también preocupándose por las facturas de los servicios públicos. Son facturas que nos vienen
                cada mes y servicios que no nos queda más remedio que pagar, pero hasta ahora no había manera de asegurarse de que se obtenía una buena oferta. Creemos que no es justo que en teoría
                podamos ahorrarnos un dinero, pero no haya forma de comparar todos los precios a la vez. Con nosotros eso se acabó. Por favor, pruébalo, creemos que lo encontrarás útil. Sin recibir
                jamás spam ni llamadas automáticas. Sólo los 5 mejores precios. Si encuentras una oferta más barata, dínoslo y probablemente ganarás $100.
              </p>
              <p>Gracias,</p>
              <p>Comparacion.com</p>
            </div>

            {isLargeScreenView && (
              <div className="flex flex-col w-5/12 pt-10 text-right ">
                <MissionSVG className="ml-auto align-top" />
              </div>
            )}
          </div>

          <div className="w-full overflow-hidden xl:w-2/12"></div>
        </div>
      </div>
    </section>
  );
};
